import { all, call, put, takeLatest, select, type SagaReturnType } from 'redux-saga/effects';
import actions from './actions';
import { ERROR_MESSAGES } from '@/constants/events';
import { getConfigs } from '@/features/configs/configs';
import { getStrings } from '@/features/configs/strings';
import orgActions from '@/redux/organization/actions';
import { getOrigin } from '@/utils';
import { prepareErrorMessage } from '@/utils/global';
import { fireKAnalyticsError } from '@/utils/koalaAnalytics';
import { type RootState } from '@/types/app';

function* fetchConfigsSaga(action: ReturnType<typeof actions.fetchConfigs>) {
  const state: RootState = yield select();
  const organization = state.app.organization?.organization;

  try {
    const origin = getOrigin(window.location.host);

    // Fetch configs and strings on both server and client
    const [strings, webConfig]: [
      SagaReturnType<typeof getStrings>,
      SagaReturnType<typeof getConfigs>,
    ] = yield all([
      call(getStrings, action.queryClient, origin, organization.id),
      call(getConfigs, action.queryClient, origin, organization.id),
    ]);

    yield all([
      put(actions.fetchConfigsSuccess(action.clientOrServer, 'webConfig', webConfig)),
      put(actions.fetchConfigsSuccess(action.clientOrServer, 'strings', strings)),
      // @ts-expect-error `organizationConfig` is mistyped.
      put(orgActions.fetchOrganizationSuccess(organization)),
    ]);
  } catch (error) {
    yield all([
      put(actions.fetchConfigsFailure(action.clientOrServer)),
      put(orgActions.fetchOrganizationFail()),
    ]);
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      null,
      error,
    );
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_BATCH_CONFIGS_ERROR, error, errorResponse);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_CONFIGS, fetchConfigsSaga);
}
