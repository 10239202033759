import {
  type CONVEYANCE_TYPES,
  type PaymentDetails,
  type SubmitBasketData,
  type Basket,
  type BasketOrder,
} from '@koala/sdk';

const orderStatusActions = {
  ORDER_STATUS_MESSAGE_CLEAR: '/koala/orderStatus/ORDER_STATUS_MESSAGE_CLEAR',
  ORDER_STATUS_MESSAGE_ERROR_SET: '/koala/orderStatus/ORDER_STATUS_MESSAGE_ERROR_SET',

  ORDER_NOT_INITIALIZED: '/koala/orderStatus/ORDER_NOT_INITIALIZED',
  ORDER_PENDING: '/koala/orderStatus/ORDER_PENDING',

  INITIALIZE_ORDER: '/koala/orderStatus/INITIALIZE_ORDER',
  INITIALIZE_ORDER_ERROR: '/koala/orderStatus/INITIALIZE_ORDER_ERROR',
  INITIALIZE_ORDER_RECONCILIATION: '/koala/orderStatus/INITIALIZE_ORDER_RECONCILIATION',
  POST_INITIALIZE_ORDER: '/koala/orderStatus/POST_INITIALIZE_ORDER',
  DESTROY_ORDER: '/koala/orderStatus/DESTROY_ORDER',

  VALIDATE_ORDER: '/koala/orderStatus/VALIDATE_ORDER',

  PROMO_CODE_ADD: '/koala/orderStatus/PROMO_CODE_ADD',
  PROMO_CODE_REMOVE: '/koala/orderStatus/PROMO_CODE_REMOVE',

  BASKET_REWARDS_GET: '/koala/orderStatus/BASKET_REWARDS_GET',
  BASKET_REWARDS_ADD: '/koala/orderStatus/BASKET_REWARDS_ADD',
  BASKET_REWARDS_REMOVE: '/koala/orderStatus/BASKET_REWARDS_REMOVE',

  TIP_ADD: '/koala/orderStatus/TIP_ADD',

  UTENSILS_SET: '/koala/orderStatus/UTENSILS_SET',

  BASKET_INSTRUCTIONS_SET: '/koala/orderStatus/BASKET_INSTRUCTIONS_SET',

  CONVEYANCE_SET: '/koala/orderStatus/CONVEYANCE_SET',
  CONVEYANCE_DELIVERY_SET: '/koala/orderStatus/CONVEYANCE_DELIVERY_SET',

  WANTED_AT_BASKET_SET: '/koala/orderStatus/WANTED_AT_BASKET_SET',
  WANTED_AT_BASKET_REMOVE: '/koala/orderStatus/WANTED_AT_BASKET_REMOVE',

  ORDER_SUBMISSION_TRIGGER: '/koala/orderStatus/ORDER_SUBMISSION_TRIGGER',
  ORDER_SUBMISSION_PCI_ERROR: '/koala/orderStatus/ORDER_SUBMISSION_PCI_ERROR',
  ORDER_SUBMISSION_PCI_FAILURE: '/koala/orderStatus/ORDER_SUBMISSION_PCI_FAILURE',
  ORDER_SUBMISSION_START: '/koala/orderStatus/ORDER_SUBMISSION_START',
  ORDER_SUBMISSION_ERROR: '/koala/orderStatus/ORDER_SUBMISSION_ERROR',

  STORED_VALUE_POST: '/koala/orderStatus/STORED_VALUE_POST',

  ORDER_SUBMISSION_DIGITAL_WALLET_TRIGGER:
    '/koala/orderStatus/ORDER_SUBMISSION_DIGITAL_WALLET_TRIGGER',

  orderStatusMessageClear: () => ({
    type: orderStatusActions.ORDER_STATUS_MESSAGE_CLEAR,
  }),
  orderStatusMessageErrorSet: (message: string) => ({
    type: orderStatusActions.ORDER_STATUS_MESSAGE_ERROR_SET,
    message,
  }),
  orderPending: () => ({
    type: orderStatusActions.ORDER_PENDING,
  }),
  orderNotInitialized: () => ({
    type: orderStatusActions.ORDER_NOT_INITIALIZED,
  }),
  initializeOrder: (basketOrder: BasketOrder, locationId: number, wantedAt?: string) => ({
    type: orderStatusActions.INITIALIZE_ORDER,
    basketOrder,
    locationId,
    wantedAt,
  }),
  initializeOrderError: (message: string) => ({
    type: orderStatusActions.INITIALIZE_ORDER_ERROR,
    message,
  }),
  initializeOrderReconciliation: (message: string) => ({
    type: orderStatusActions.INITIALIZE_ORDER_RECONCILIATION,
    message,
  }),
  postInitializeOrder: (
    basketOrder: BasketOrder,
    basket: Basket,
    fulfillmentType: CONVEYANCE_TYPES,
    wantedAtDay?: string,
    wantedAt?: string,
  ) => ({
    type: orderStatusActions.POST_INITIALIZE_ORDER,
    basketOrder,
    basket,
    fulfillmentType,
    wantedAtDay,
    wantedAt,
  }),
  destroyOrder: () => ({
    type: orderStatusActions.DESTROY_ORDER,
  }),
  validateOrder: (basketId: string, locationId: number) => ({
    type: orderStatusActions.VALIDATE_ORDER,
    basketId,
    locationId,
  }),
  promoCodeAdd: (promoCode: string) => ({
    type: orderStatusActions.PROMO_CODE_ADD,
    promoCode,
  }),
  promoCodeRemove: () => ({
    type: orderStatusActions.PROMO_CODE_REMOVE,
  }),
  conveyanceSet: () => ({
    type: orderStatusActions.CONVEYANCE_SET,
  }),
  conveyanceDeliverySet: (instructions?: string, staleId?: number) => ({
    type: orderStatusActions.CONVEYANCE_DELIVERY_SET,
    instructions,
    staleId,
  }),
  wantedAtBasketSet: (wantedAt: string, basketId?: string, locationId?: number) => ({
    type: orderStatusActions.WANTED_AT_BASKET_SET,
    wantedAt,
    basketId,
    locationId,
  }),
  wantedAtBasketRemove: () => ({
    type: orderStatusActions.WANTED_AT_BASKET_REMOVE,
  }),
  basketRewardsGet: () => ({
    type: orderStatusActions.BASKET_REWARDS_GET,
  }),
  basketRewardsAdd: () => ({
    type: orderStatusActions.BASKET_REWARDS_ADD,
  }),
  basketRewardsRemove: () => ({
    type: orderStatusActions.BASKET_REWARDS_REMOVE,
  }),
  tipAdd: (tipAmount: number, isCustom: boolean) => ({
    type: orderStatusActions.TIP_ADD,
    tipAmount,
    isCustom,
  }),
  utensilsSet: (utensils: boolean) => ({
    type: orderStatusActions.UTENSILS_SET,
    utensils,
  }),
  basketInstructionsSet: (basketInstructions: string) => ({
    type: orderStatusActions.BASKET_INSTRUCTIONS_SET,
    basketInstructions,
  }),
  storedValuePost: (cardToken: string, cardNumber: string, pin?: string, captcha?: string) => ({
    type: orderStatusActions.STORED_VALUE_POST,
    cardToken,
    cardNumber,
    pin,
    captcha,
  }),
  orderSubmissionTrigger: (message: string, captcha?: string | null) => ({
    type: orderStatusActions.ORDER_SUBMISSION_TRIGGER,
    message,
    captcha,
  }),
  orderSubmissionPciError: (message: string) => ({
    type: orderStatusActions.ORDER_SUBMISSION_PCI_ERROR,
    message,
  }),
  orderSubmissionPciFailure: () => ({
    type: orderStatusActions.ORDER_SUBMISSION_PCI_FAILURE,
  }),
  orderSubmissionStart: (submitBasketData: SubmitBasketData, captcha?: string | null) => ({
    type: orderStatusActions.ORDER_SUBMISSION_START,
    submitBasketData,
    captcha,
  }),
  orderSubmissionError: (message: string) => ({
    type: orderStatusActions.ORDER_SUBMISSION_ERROR,
    message,
  }),
  orderDigitalWalletTrigger: (
    paymentDetails: PaymentDetails,
    message: string,
    captcha?: string | null,
  ) => ({
    type: orderStatusActions.ORDER_SUBMISSION_DIGITAL_WALLET_TRIGGER,
    message,
    paymentDetails,
    captcha,
  }),
};

export default orderStatusActions;
