import { type IOrderDetailsAction, orderDetailsActionTypes } from './actions';
import { type RootState } from '@/types/app';
import { type IOrderDetailsState } from '@/types/orderDetails';
import { getISODateInUTC } from '@/utils/dates';

/** @TODO decouple IOrderDetailsState from the SDK response type. */
// @ts-expect-error
export const initialState: IOrderDetailsState = {
  organization_id: null,
  store_location_id: null,
  store_location: null,
  order_id: null,
  basket_id: null,
  balance: null,
  ready_at: null,
  total: null,
  status_id: null,
  client_id: null,
  is_complete: null,
  order_data: {
    customer: {
      first_name: null,
      last_name: null,
      email: null,
      contact_number: null,
    },
    order: {
      id: null,
      subtotal: null,
      sales_tax: null,
      total: null,
      ordered_at: null,
      order_number: null,
      order_items: null,
      payment_card: null,
      payment_card_type: null,
      promo_discount: null,
      ready_at: null,
      arrival_status: null,
    },
    basket: {
      id: null,
      special_instructions: null,
      tip: null,
      subtotal: null,
      sales_tax: null,
      total: null,
      time_estimate_minutes: null,
      promo_discount: null,
      wanted_at: null,
      conveyance_type: null,
      location: null,
      basket_items: null,
    },
    payment: null,
  },
  id: null,
  updated_at: null,
  created_at: null,
  ip: null,
  arrival_status: null,
  meta: {
    loading: false,
    error: null,
    params: null,
    lastUpdatedAt: null,
  },
} as IOrderDetailsState;

export const orderDetails = (
  state: IOrderDetailsState = initialState,
  action: IOrderDetailsAction,
): IOrderDetailsState => {
  switch (action.type) {
    case orderDetailsActionTypes.FETCH_ORDER_DETAILS_BY_ORDER_ID: {
      const partialState: Partial<IOrderDetailsState> = {
        order_id: action.order_id,
        meta: {
          error: null,
          loading: true,
          params: null,
        },
      };

      return Object.assign({}, state, partialState);
    }
    case orderDetailsActionTypes.FETCH_ORDER_DETAILS_BY_ORDER_ID_SUCCESS: {
      const partialState: Partial<IOrderDetailsState> = {
        meta: {
          error: null,
          loading: false,
          params: null,
          lastUpdatedAt: getISODateInUTC(),
        },
        ...action.data,
      };

      return Object.assign({}, state, partialState);
    }
    case orderDetailsActionTypes.FETCH_ORDER_DETAILS_BY_ORDER_ID_FAIL: {
      const partialState: Partial<IOrderDetailsState> = {
        meta: {
          error: action.error,
          loading: false,
          params: null,
          lastUpdatedAt: getISODateInUTC(),
        },
      };

      return Object.assign({}, state, partialState);
    }
    case orderDetailsActionTypes.ADD_ORDER_DETAILS_STATE: {
      const partialState: Partial<IOrderDetailsState> = {
        meta: {
          error: null,
          loading: false,
          params: null,
          lastUpdatedAt: getISODateInUTC(),
        },
        ...action.data,
      };

      return Object.assign({}, state, partialState);
    }
    case orderDetailsActionTypes.CLEAR_ORDER_DETAILS_STATE:
      window.localStorage.removeItem('source');
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export const orderDetailsSelector = (state: RootState) => state.app.orderDetails;
